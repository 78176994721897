import { translate } from "../index"; 

export const LoadingPage = () => {
    return (
        <div className="centeredBody">
            <div className="message">
                <h1 className="loadingEllipsis">{translate("LOADING")}</h1>
            </div>
        </div>
    );
};