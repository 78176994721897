import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import { CustomPageLowAuthService } from "./authService.js";
import App from "./App";
import Cookies from "js-cookie";

import "bootstrap/dist/css/bootstrap.min.css";
import "./styles/index.css";

export const CallCenterInstanceName = "test-call-center";

// Look for optional "lang" query parameter and use it if provided
const langParam = new URLSearchParams(window.location.search).get("lang");
if (langParam) {
    // Store preferred language in a cookie so that it's persistent across requests to this app
    Cookies.set("lang", langParam);
}

const Language = Cookies.get("lang") || "ja";
const Translations = {
    en: {
        REQUEST_SUBMITTED_PLEASE_WAIT:
            "Request submitted. Please wait for the meeting to start...",
        SUPPORT_REQUEST_CANCELED_YOU_MAY_CLOSE:
            "Support request has been canceled. You may close this window.",
        SUPPORT_REQUEST_FORM: "Support Request Form",
        REQUEST_SUPPORT: "Request Support",
        SUBMITTING_YOUR_REQUEST: "Submitting your request for support...",
        CANCEL_REQUEST: "Cancel Request",
        REQUEST_CALLBACK: "Request Callback",
        URGENT: "Urgent",
        NOT_URGENT: "Not Urgent",
        PLEASE_ASK_YOUR_ADMINISTRATOR:
            "Please ask your administrator for instructions.",
        ACCESS_RESTRICTED: "Access Restricted",
        CUSTOMER_PORTAL: "Customer Portal",
        CALL_CENTER_LOBBY: "Call Center Lobby",
        COMMENT: "Comment",
        LOADING: "Loading",
        CALL_CENTER_IS_BUSY: "The Call Center is experiencing longer than expected wait times. Please continue waiting or consider requesting a callback."
    },
    ja: {
        REQUEST_SUBMITTED_PLEASE_WAIT: "呼出し中です。しばらくお待ちください。 ",
        SUPPORT_REQUEST_CANCELED_YOU_MAY_CLOSE: "依頼をキャンセルしました。ウィンドーを閉じてください",
        SUPPORT_REQUEST_FORM: "サポート依頼フォーム",
        REQUEST_SUPPORT: "サポートを依頼する",
        SUBMITTING_YOUR_REQUEST: "呼出し中です",
        CANCEL_REQUEST: "呼出しをキャンセルする",
        REQUEST_CALLBACK: "コールバックを依頼する",
        URGENT: "至急コールバック依頼",
        NOT_URGENT: "コールバック依頼",
        PLEASE_ASK_YOUR_ADMINISTRATOR: "管理者と連絡して下さい",
        ACCESS_RESTRICTED: "アクセス権限エラー",
        CUSTOMER_PORTAL: "カスタマーポータル",
        CALL_CENTER_LOBBY: "サポートセンターロビー",
        COMMENT: "コメント",
        LOADING: "ローディング中",
        CALL_CENTER_IS_BUSY: "対応の待ち時間が予想よりも長くなっています。そのままお待ちになるか、コールバックのリクエストを検討してください。 "
    },
};
export const translate = (key) => {
    const table = Translations[Language];
    if (!table) {
        return key;
    }
    return table[key] || key;
};

export const authService = new CustomPageLowAuthService();

let currentPage = {};
export const setCurrentPage = (page) => (currentPage = page);
export const getCurrentPage = () => currentPage;

document.title = translate("CUSTOMER_PORTAL");
const manifest = {
    name: document.title,
    short_name: document.title,
    description: document.title,
    start_url: window.location.href
  };
const stringManifest = JSON.stringify(manifest);
const blob = new Blob([stringManifest], {type: 'application/json'});
const manifestURL = URL.createObjectURL(blob);
document.querySelector('#manifest-placeholder').setAttribute('href', manifestURL);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <Router>
        <App authService={authService} />
    </Router>
);
