import React, { useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { Button } from 'react-bootstrap';
import { CallCenter } from "../onsight-plus-ext";
import { CallCenterInstanceName, getCurrentPage, translate } from "../index";

export const RequestForm = () => {
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [messageVisibility, setMessageVisibility] = useState("hidden");
    const [comments, setComments] = useState("");
    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();
    const email = searchParams.get("email");

    const handleSubmit = async () => {
        setButtonDisabled(true);
        setMessageVisibility("unset");
        
        const pathParts = getCurrentPage().name.split("/");
        const requesterCompany = pathParts.length > 0 ? pathParts[0] : "";
        const requesterUser = pathParts.length > 1 ? pathParts[1] : "";

        const newWindow = window.open("about:blank", "_blank");
        const response = await CallCenter.makeRequest(CallCenterInstanceName, {
            requesterCompany,
            requesterUser,
            requesterEmail: email,
            subject: "",
            requesterComments: comments
        });
        if (response.status === 201 && response.data.id) {
            let pathname = location.pathname;
            if (!pathname.endsWith('/')) {
                pathname += '/';
            }
            newWindow.location.href = `${pathname}lobby?ccReqId=${response.data.id}&email=${email}`;
            setComments("");
            setButtonDisabled(false);
            setMessageVisibility("hidden");
        }
    };

    return (
        <>
            <div className="card" style={{margin: "20px"}}>
                <div className="card-header font-weight-bold">{translate("SUPPORT_REQUEST_FORM")}</div>
                <div style={{ height: "100%", width: "100%", padding: "30px" }} className="">
                    <div style={{ display: "flex", flexDirection: "row", height: "100%" }}>
                        <div style={{ flexGrow: "1" }}>
                            <div style={{ height: "100%", width: "100%" }}>
                                <Button onClick={handleSubmit} disabled={buttonDisabled} className="btn btn-primary ml-auto col-auto" type="submit">
                                    {translate("REQUEST_SUPPORT")}
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <br />
            <p />
            <span style={{ visibility: messageVisibility }}>{translate("SUBMITTING_YOUR_REQUEST")}</span>
        </>
    );
};