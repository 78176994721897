import React, { useEffect } from "react";
import { authService } from "..";

const CustomPageNoAuth = (props) => {
    useEffect(() => {
        authService.login();
    }, []);

    return (
        <>
            {props.children}
        </>
    );
};

export default CustomPageNoAuth;