import { authService } from "./index.js";
import axios from "axios";

const requestOptions = async (scopes, extraHeaders) => {
    const authHeaders = await authService.getAuthHeaders(scopes);
    return {
        headers: { ...authHeaders, ...extraHeaders },
    };
};

const get = async (path, scopes, extraHeaders) => {
    return await axios.get(
        `${process.env.REACT_APP_ONSIGHT_PLUS_EXT}/v1${path}`,
        await requestOptions(scopes, extraHeaders)
    );
};

const post = async (path, body, scopes, extraHeaders) => {
    return await axios.post(
        `${process.env.REACT_APP_ONSIGHT_PLUS_EXT}/v1${path}`,
        body,
        await requestOptions(scopes, extraHeaders)
    );
};

const put = async (path, body, scopes, extraHeaders) => {
    return await axios.put(
        `${process.env.REACT_APP_ONSIGHT_PLUS_EXT}/v1${path}`,
        body,
        await requestOptions(scopes, extraHeaders)
    );
};

export class UserWebApps {
    /**
     * Gets all relevant app configs for the current user.
     * This should be called immediately after the user has been authenticated
     * in order to determine which configurations (layouts) are applicable to that user.
     * @returns
     */
    static async getUserConfigs(pageId) {
        const response = await get(
            `/web-apps/${process.env.REACT_APP_B2C_CLIENT_ID}/pages/${pageId}/user-configs`
        );
        return response.data;
    }

    static async getUserPages() {
        const response = await get(
            `/web-apps/${process.env.REACT_APP_B2C_CLIENT_ID}/user-pages`
        );
        return response.data;
    }
}

export class CallCenter {
    static requesterHubUrl = `${process.env.REACT_APP_ONSIGHT_PLUS_EXT}/v1/hubs/cc-requester`;

    static makeRequest(extNameId, request) {
        return post(`/call-center/${extNameId}/requests`, request);
    }

    static updateRequest(extNameId, reqId, updateData) {
        return post(`/call-center/${extNameId}/requests/${reqId}`, updateData);
    }
}
