import React, { useEffect, useRef, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useSearchParams } from "react-router-dom";
import { HubConnectionBuilder } from '@microsoft/signalr';
import { CallCenter } from '../onsight-plus-ext';
import { authService, CallCenterInstanceName, translate } from "../index";
import CallbackForm from './CallbackForm';

const CallCenterLobby = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const ccRequestId = searchParams.get("ccReqId");
    const email = searchParams.get("email");
    const [requestCallback, setRequestCallback] = useState(false);
    const [canceled, setCanceled] = useState(false);
    const [callbackAllowed, setCallbackAllowed] = useState(Boolean(email));   // need an email before requesting callback
    const [progressValue, setProgressValue] = useState(0);
    const progressValueRef = useRef(0);
    const TimeoutThreshold = 60;
    const [timedOut, setTimedOut] = useState(false);

    const handleCancelClick = async () => {
        const response = await CallCenter.updateRequest(CallCenterInstanceName, ccRequestId, {
            id: ccRequestId,
            status: "Canceled"
        });
        setCanceled(true);
        window.close();
    };

    useEffect(() => {
        document.title = translate("CALL_CENTER_LOBBY");
        if (!ccRequestId) {
            return;
        }

        const websocketUrl = `${CallCenter.requesterHubUrl}?tenant=${authService.getUserAccount().activeTenant}&extName=${CallCenterInstanceName}&ccReqId=${ccRequestId}`;
        const connection = new HubConnectionBuilder()
            .withUrl(websocketUrl)
            .withAutomaticReconnect()
            .build();

        connection.start()
            .then(result => {
                connection.on('BeginOnsightSession', async ocUrl => {
                    console.log(ocUrl);
                    await connection.send("AcknowledgeNewOnsightSession");
                    window.location.href = ocUrl;
                });
            })
            .catch(e => console.log('Connection failed: ', e));

        // Use timer to show progress indicator to user
        setInterval(() => setProgressValue(progressValueRef.current + 1), 1000);            
    }, []);
    
    useEffect(() => {
        progressValueRef.current = progressValue;
        if (progressValue >= TimeoutThreshold) {
            setTimedOut(true);
        }
    }, [progressValue]);

    return (
        <div style={{margin: "20px"}}>
            <h3>{translate("REQUEST_SUBMITTED_PLEASE_WAIT")}</h3>
            <h3>&nbsp;{".".repeat(progressValue % TimeoutThreshold)}</h3>
            <br />
            <Button hidden={requestCallback || canceled} onClick={handleCancelClick}>{translate("CANCEL_REQUEST")}</Button>
            &nbsp;&nbsp;&nbsp;
            <Button hidden={requestCallback || canceled || !callbackAllowed} onClick={() => setRequestCallback(true)}>{translate("REQUEST_CALLBACK")}</Button>
            <br />
            <p />
            <h4 hidden={!timedOut}>{translate("CALL_CENTER_IS_BUSY")}</h4>
            <h4 hidden={!canceled}>{translate("SUPPORT_REQUEST_CANCELED_YOU_MAY_CLOSE")}</h4>
            <CallbackForm hidden={!requestCallback} requestId={ccRequestId} email={email} />
        </div>
    );
};

export default CallCenterLobby;