import { WebAppConfigs } from "./onsight-plus";

export const rowHeight = 1;

export const updateConfigs = async (
    pageId,
    config,
    type,
    configId,
    isAdmin
) => {
    let objArr = [];
    if (type === "UIMappingInputs") {
        config.forEach((tmi) => {
            if (tmi !== undefined) {
                let obj = {};
                obj.parent_id = tmi.parent_id;
                obj.no = tmi.no;
                obj.type = tmi.type;
                let inputs = [];
                tmi.inputs.forEach((i) => {
                    let input = {};
                    input.key = i.key;
                    let props = {};
                    props.type = i.props.type;
                    props.id = i.props.id;
                    props.blockId = i.props.blockId;
                    input.props = props;
                    inputs.push(input);
                });
                obj.inputs = inputs;
                objArr.push(obj);
            }
        });
    } else if (type === "UIBlockConfigs") {
        config.forEach((bc) => {
            let obj = {};
            obj.key = bc.key;
            let props = {};
            props.blockId = bc.props.blockId;
            props.id = bc.props.id;
            props.blockKeyId = bc.props.blockKeyId;
            props.selected = bc.props.selected;
            props.content = bc.props.content;
            let mappingInputs = [];
            bc.props.mappingInput.forEach((mi) => {
                if (bc.props.id === mi.parent_id && mi.inputs.count !== 0) {
                    let mappingInput = {};
                    mappingInput.parent_id = mi.parent_id;
                    mappingInput.no = mi.no;
                    mappingInput.type = mi.type;
                    let inputs = [];
                    mi.inputs.forEach((i) => {
                        let input = {};
                        input.key = i.key;
                        let props = {};
                        props.type = i.props.type;
                        props.id = i.props.id;
                        props.blockId = i.props.blockId;
                        input.props = props;
                        inputs.push(input);
                    });
                    mappingInput.inputs = inputs;
                    mappingInputs.push(mappingInput);
                }
            });
            props.mappingInput = mappingInputs;
            obj.props = props;
            objArr.push(obj);
        });
    } else {
        objArr = config;
    }
    try {
        let configToUpdate = [];
        if (type === "UILayouts") {
            configToUpdate = await WebAppConfigs.get(
                pageId,
                configId,
                "UILayouts"
            );
        } else {
            configToUpdate = await WebAppConfigs.get(
                pageId,
                configId,
                "UIConfigs"
            );
        }

        configToUpdate.objectValue[type] = objArr;

        if (isAdmin) {
            if (type === "UILayouts") {
                await WebAppConfigs.updateUILayout(
                    pageId,
                    configToUpdate.relationalId,
                    configToUpdate["objectValue"]
                );
            } else {
                await WebAppConfigs.updateUIConfig(
                    pageId,
                    configToUpdate.relationalId,
                    configToUpdate["objectValue"]
                );
            }
        }
    } catch (err) {
        console.error(err);
    }
};

export const getUpdatedLayouts = async (currentLayouts, isAdmin) => {
    if (currentLayouts !== undefined && currentLayouts.length !== 0) {
        let updatedLayouts;
        const contain = document.getElementById("navbar-container-main");
        const rlg = document.getElementById("main-rgl");
        if (rlg !== null && rlg !== undefined) {
            const draggableParent = rlg.firstChild;
            const draggableChildren = draggableParent.children;
            for (let i = 0; i < draggableChildren.length; i++) {
                const fstChild = draggableChildren[i].firstChild;
                const target = document.getElementById(
                    draggableChildren[i].firstChild.id
                );
                if (target !== null && target !== undefined) {
                    const contain_w = contain.clientWidth;
                    const w = target.clientWidth;
                    const h = target.clientHeight + target.offsetTop;
                    const minusPos = fstChild.id.indexOf("-") + 1;
                    const id = fstChild.id.substring(minusPos);
                    if (h !== 0) {
                        updatedLayouts = resizeLayout(
                            currentLayouts,
                            contain_w,
                            w,
                            h,
                            id,
                            isAdmin
                        );
                    }
                }
            }
        }

        if (updatedLayouts !== undefined) {
            return updatedLayouts;
        }
    }
};

/**
 * Given a height and width in pixel values, calculate grid units.
 * @param  {PositionParams} positionParams  Parameters of grid needed for coordinates calcluations.
 * @param  {Number} height                  Height in pixels.
 * @param  {Number} width                   Width in pixels.
 * @param  {Number} x                       X coordinate in grid units.
 * @param  {Number} y                       Y coordinate in grid units.
 * @return {Object}                         w, h as grid units.
 */
function calcWH(positionParams, width, height, x, y) {
    const { margin, maxRows, cols, rowHeight } = positionParams;
    const colWidth = calcGridColWidth(positionParams);

    let w = Math.ceil((width + margin[0]) / (colWidth + margin[0]));
    let h = Math.ceil((height + margin[1]) / (rowHeight + margin[1]));

    // Capping
    w = clamp(w, 0, cols - x);
    h = clamp(h, 0, maxRows - y);
    return { w, h };
}

function calcGridColWidth(positionParams) {
    const { margin, containerPadding, containerWidth, cols } = positionParams;
    return (
        (containerWidth - margin[0] * (cols - 1) - containerPadding[0] * 2) /
        cols
    );
}

// Similar to _.clamp
function clamp(num, lowerBound, upperBound) {
    return Math.max(Math.min(num, upperBound), lowerBound);
}

function resizeLayout(allLayouts, width, w, h, id, isAdmin) {
    let PositionParams = {};
    let wh;

    PositionParams = {
        margin: [0, 0],
        containerPadding: [0, 0],
        containerWidth: width,
        cols: 12,
        rowHeight: rowHeight,
        maxRows: 100000,
    };

    if (id !== undefined) {
        const i = allLayouts.indexOf(allLayouts.find((al) => al.i === id));
        if (i !== -1) {
            const targetBlock = document.querySelector("#" + id);
            if (targetBlock.innerHTML.indexOf("spacerBlock") === -1) {
                wh = calcWH(
                    PositionParams,
                    w,
                    h,
                    allLayouts[i].x,
                    allLayouts[i].y
                );
                allLayouts[i].w = wh.w > 12 ? 12 : wh.w;
                allLayouts[i].h = wh.h;
                if (isAdmin) {
                    allLayouts[i].isDraggable = true;
                    allLayouts[i].isResizable = true;
                } else {
                    allLayouts[i].isDraggable = false;
                    allLayouts[i].isResizable = false;
                }
            }
        }
    }
    return allLayouts;
}
